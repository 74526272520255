var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.invoice
      ? _c("div", { staticClass: "p-3" })
      : _c(
          "div",
          { staticClass: "d-flex justify-content-end" },
          [
            _vm.invoice.status === _vm.INVOICE_STATUS.draft
              ? _c("EditProformaModal")
              : _vm._e(),
            _vm.invoice.status === _vm.INVOICE_STATUS.issued
              ? _c("DownloadInvoice", {
                  attrs: { invoice: _vm.invoice },
                  on: { error: _vm.displayError },
                })
              : _vm._e(),
            _vm.invoice.status === _vm.INVOICE_STATUS.draft
              ? _c(
                  "ui-button",
                  {
                    staticClass: "ml-2",
                    attrs: {
                      face: _vm.FACES.outline,
                      size: _vm.SIZES.small,
                      "data-test-id": "send-for-review",
                    },
                    on: { clickbutton: _vm.onSendForReviewClick },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("FleetInvoicing.Common.Invoices.review.action")
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm.invoice.status === _vm.INVOICE_STATUS.inReview
              ? _c(
                  "ui-dropdown",
                  [
                    _c(
                      "span",
                      { attrs: { slot: "trigger" }, slot: "trigger" },
                      [_vm._v(" " + _vm._s(_vm.invoiceStatusCapitalized) + " ")]
                    ),
                    _c("ui-dropdown-actions", {
                      attrs: {
                        slot: "content",
                        "data-test-id": "dropdown-actions",
                      },
                      domProps: { actions: _vm.actionList },
                      slot: "content",
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.currentOperation === _vm.INVOICE_ACTIONS.sendForReview
              ? _c("SendInvoiceToReviewModal", {
                  attrs: {
                    invoice: _vm.invoice,
                    title: "Send to client for review",
                  },
                  on: { closeModal: _vm.closeCurrentAction },
                })
              : _vm.currentOperation === _vm.INVOICE_ACTIONS.modify
              ? _c("ModifyInvoiceAlert", {
                  on: { modify: _vm.onModify, cancel: _vm.closeCurrentAction },
                })
              : _vm.currentOperation === _vm.INVOICE_ACTIONS.issue
              ? _c("IssueInvoiceAlert", {
                  on: { issue: _vm.onIssue, cancel: _vm.closeCurrentAction },
                })
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }