<script>
import last from 'lodash/last';
import upperFirst from 'lodash/upperFirst';
import upperCase from 'lodash/upperCase';

import InvoiceLine from './InvoiceLine';

export default {
  name: 'InvoiceTable',
  components: {
    InvoiceLine,
  },
  props: {
    grandTotal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    groups: {
      type: Array,
      default: () => [],
    },
    lines: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tableHeadClasses() {
      return this.grandTotal
        ? 'emobg-background-color-ink-light emobg-color-white'
        : 'emobg-background-color-ground-lightest';
    },
    detailsHeaderColspan() {
      return this.grandTotal ? 2 : 1;
    },
  },
  methods: {
    upperCase,
    upperFirst,
    isLastIn: (item, collection) => item === last(collection),
  },
};
</script>

<template>
  <section class="InvoiceTable">
    <h3
      class="InvoiceTable__title emobg-font-xx-large mb-3"
      data-test-id="invoice-table-title"
    >
      {{ title }}
    </h3>
    <table class="w-100 text-left emobg-border-1 emobg-border-color-ground-light mb-4">
      <thead :class="tableHeadClasses">
        <tr>
          <th
            class="pl-2 py-3"
            :colspan="detailsHeaderColspan"
          >
            {{ upperFirst($t('Common.Descriptions.details')) }}
          </th>
          <th
            v-if="!grandTotal"
            class="py-3"
          >
            {{ upperFirst($t('Common.Descriptions.units')) }}
          </th>
          <th class="py-3">
            {{ (upperFirst($t('Common.Descriptions.net_element', {element: $t('Common.Descriptions.amount')}))) }}
          </th>
          <th class="py-3">
            {{ (upperFirst($t('Common.Business.tax_element', {element: $t('Common.Business.rate')}))) }}
          </th>
          <th class="py-3">
            {{ upperCase($t('Common.Business.vat')) }}
          </th>
          <th class="py-3">
            {{ upperFirst($t('Common.Descriptions.total')) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <InvoiceLine
          v-for="(line, index) in lines"
          :key="`${title}-line${index}`"
          :grand-total="grandTotal"
          v-bind="{...line}"
          :first="index === 0"
          :last="index === lines.length - 1"
        />
        <template v-for="group in groups">
          <th
            :key="group.id"
            colspan="6"
            class="emobg-font-weight-bold py-4 pl-3"
          >
            {{ group.name }}
          </th>
          <InvoiceLine
            v-for="(line, index) in group.lines"
            :key="`${group.id}-line${index}`"
            v-bind="{...line}"
            :data-test-id="`group[${group.id}] - ${line.name}`"
            :last="isLastIn(group, groups) && isLastIn(line, group.lines)"
          />
        </template>
      </tbody>
    </table>
  </section>
</template>
