var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "FiscalInfo emobg-font-weight-bold mb-6" },
    [
      _c(
        "h2",
        {
          staticClass:
            "emobg-font-default emobg-font-weight-bold text-uppercase mb-2",
          attrs: { "data-test-id": "FiscalInfo__title" },
        },
        [_vm._v(" " + _vm._s(_vm.sentenceCase(_vm.title)) + " ")]
      ),
      _vm._l(_vm.concat(_vm.lines), function (line, index) {
        return _c(
          "p",
          {
            key: index,
            staticClass: "mb-2 emobg-color-ink-light",
            attrs: { "data-test-id": "FiscalInfo__line" },
          },
          [_vm._v(" " + _vm._s(line) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }