var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { attrs: { "data-test-id": "compiledInvoice" } },
    [
      _vm.shouldShowDraftMessage
        ? _c(
            "ui-alert",
            {
              staticClass: "w-100",
              attrs: {
                "data-test-id": "draft-warning",
                color: _vm.COLORS.warning,
              },
            },
            [
              _vm._v(
                " Once the proforma is correct, please send it for review to client before issuing the final invoice. "
              ),
            ]
          )
        : _vm._e(),
      _c("header", { staticClass: "mt-4 d-flex justify-content-between" }, [
        _c(
          "section",
          { staticClass: "InvoiceDetailView__issuer-info" },
          [
            _c("img", {
              staticClass: "InvoiceDetailView__issuer-info--logo",
              attrs: {
                src: _vm.get(_vm.invoiceCompiled, "issuerCompany.logo"),
                alt: _vm.get(_vm.invoiceCompiled, "issuerCompany.name"),
              },
            }),
            _c("FiscalInfo", {
              attrs: {
                title: _vm.upperFirst(
                  _vm.$t("Common.Business.issuer_element", {
                    element: _vm.$t("Common.Descriptions.company_element", {
                      element: _vm.$t("Common.Descriptions.details"),
                    }),
                  })
                ),
                lines: _vm.issuerInfoLines,
                "data-test-id": "issuer-details",
              },
            }),
          ],
          1
        ),
        _c(
          "section",
          { staticClass: "InvoiceDetailView__customer-info" },
          [
            _vm.shouldShowInvoiceDate
              ? _c("FiscalInfo", {
                  attrs: {
                    lines: [_vm.invoiceCompiled.date],
                    title: "Date",
                    "data-test-id": "issue-date",
                  },
                })
              : _vm._e(),
            _c("FiscalInfo", {
              attrs: {
                title: _vm.upperFirst(
                  _vm.$t("Common.Descriptions.invoice_element", {
                    element: _vm.$t("Common.Descriptions.id"),
                  })
                ),
                lines: [_vm.invoiceCompiled.reference],
                "data-test-id": "invoice-id",
              },
            }),
            _c("FiscalInfo", {
              attrs: {
                title: _vm.upperCase(
                  _vm.$t("Common.Actions.purchase_element", {
                    element: _vm.$t("Common.Business.order"),
                  })
                ),
                lines: [_vm.invoiceCompiled.purchaseOrderNumber],
                "data-test-id": "purchase-order",
              },
            }),
            _c("FiscalInfo", {
              attrs: {
                title: _vm.upperCase(
                  _vm.$t("Common.Descriptions.details_element", {
                    element: _vm.$t("Common.Business.customer"),
                  })
                ),
                lines: _vm.customerInfoLines,
                "data-test-id": "customer-details",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._l(_vm.computedGroups, function (group) {
        return _c("InvoiceTable", {
          key: group.id,
          attrs: {
            title: group.name,
            groups: group.groups,
            lines: group.lines,
          },
        })
      }),
      _c("InvoiceTable", {
        staticClass: "mt-6",
        attrs: { "grand-total": "", lines: [_vm.invoiceTotalLine] },
      }),
      _c("p", { staticClass: "w-50 mt-6" }, [
        _vm._v(" " + _vm._s(_vm.invoiceCompiled.footer) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }