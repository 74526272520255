var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-button",
    {
      staticClass: "ml-2",
      attrs: {
        face: _vm.FACES.outline,
        color: _vm.GRAYSCALE.inkLight,
        disabled: _vm.isDownloading,
        loading: _vm.isDownloading,
        square: "",
      },
      on: { clickbutton: _vm.download },
    },
    [
      _c("ui-icon", {
        attrs: {
          icon: _vm.ICONS.download,
          size: _vm.ICONS_SIZES.small,
          square: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }