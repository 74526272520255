<script>
import { createNamespacedHelpers } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import AlertComponent from '@/components/AlertComponent/AlertComponent';
import WarningComponent from '@/components/AlertComponent/WarningComponent';
import { scopes } from '../store/InvoicesModule';

const { mapState } = createNamespacedHelpers(
  DOMAINS_MODEL.fleetInvoicing.invoices,
);

export default {
  name: 'ModifyInvoiceAlert',
  components: {
    AlertComponent,
    WarningComponent,
  },
  computed: {
    ...mapState({
      invoice: state => state[scopes.invoice].data.data,
      isLoaded: state => state[scopes.invoice].STATUS.LOADED,
      isLoading: state => state[scopes.invoice].STATUS.LOADING,
    }),
  },
  watch: {
    isLoaded(isLoaded) {
      if (isLoaded) {
        this.$notify({
          message: 'You can now edit the invoice',
          textAction: 'OK',
        });
        this.cancel();
      }
    },
  },
  methods: {
    action() {
      this.$emit('modify');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
<template>
  <AlertComponent
    :is-loading="isLoading"
    @action="action"
    @cancel="cancel"
  >
    <WarningComponent :title="$t('FleetInvoicing.Common.Invoices.modify.warning.title')">
      <template slot="content">
        <p class="mb-3">
          {{ $t('FleetInvoicing.Common.Invoices.modify.warning.content') }}
        </p>
        <p>{{ $t('FleetInvoicing.Common.Invoices.status.warning.content') }}</p>
      </template>
    </WarningComponent>
  </AlertComponent>
</template>
