var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AlertComponent",
    {
      attrs: { "is-loading": _vm.isLoading },
      on: { action: _vm.action, cancel: _vm.cancel },
    },
    [
      _c(
        "WarningComponent",
        {
          attrs: {
            title: _vm.$t("FleetInvoicing.Common.Invoices.issue.warning.title"),
          },
        },
        [
          _c("template", { slot: "content" }, [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "FleetInvoicing.Common.Invoices.issue.warning.content"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "FleetInvoicing.Common.Invoices.status.warning.content"
                  )
                )
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }