export default {
  validate: {
    rule: /^\d+[.|,]\d{2}$/,
    message: 'The value should be a valid amount: 100.00',
  },

  serialize(currencyString) {
    const currencyWithoutSeparation = String(currencyString).replace(/[.|,]/g, '');
    return parseInt(currencyWithoutSeparation, 10);
  },

  parse(currency) {
    return (currency / 100).toFixed(2);
  },
};
