<script>
export default {
  name: 'InvoiceLine',
  props: {
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    units: {
      type: [Number, String],
      default: '',
    },
    netAmountPerUnit: {
      type: String,
      default: '',
    },
    totalGrossAmount: {
      type: String,
      required: true,
    },
    tax: {
      type: String,
      required: true,
    },
    vatPerUnit: {
      type: String,
      default: '',
    },
    totalNetAmount: {
      type: String,
      default: '',
    },
    totalVatAmount: {
      type: String,
      default: '',
    },
    last: {
      type: Boolean,
      default: false,
    },
    first: {
      type: Boolean,
      default: false,
    },
    grandTotal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    detailsColspan() {
      return this.grandTotal ? 2 : 1;
    },
  },
};
</script>

<template>
  <tr :class="{ 'emobg-font-weight-bold': grandTotal }">
    <td
      class="w-50 pl-3 py-1 bold"
      :class="{
        'pt-4': first,
        'pb-4': last,
      }"
    >
      {{ `${grandTotal ? 'Total' : name}:` }}
      <template v-if="description">
        {{ description }}
      </template>
    </td>
    <td class="py-1 w-10">
      {{ units }}
    </td>
    <td class="py-1 w-10">
      {{ grandTotal ? totalNetAmount : netAmountPerUnit }}
    </td>
    <td class="py-1 w-10">
      {{ tax }}
    </td>
    <td class="py-1 w-10">
      {{ grandTotal ? totalVatAmount : vatPerUnit }}
    </td>
    <td class="py-1 pr-3 w-10">
      {{ totalGrossAmount }}
    </td>
  </tr>
</template>
