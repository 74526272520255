var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { class: { "emobg-font-weight-bold": _vm.grandTotal } }, [
    _c(
      "td",
      {
        staticClass: "w-50 pl-3 py-1 bold",
        class: {
          "pt-4": _vm.first,
          "pb-4": _vm.last,
        },
      },
      [
        _vm._v(" " + _vm._s(`${_vm.grandTotal ? "Total" : _vm.name}:`) + " "),
        _vm.description
          ? [_vm._v(" " + _vm._s(_vm.description) + " ")]
          : _vm._e(),
      ],
      2
    ),
    _c("td", { staticClass: "py-1 w-10" }, [
      _vm._v(" " + _vm._s(_vm.units) + " "),
    ]),
    _c("td", { staticClass: "py-1 w-10" }, [
      _vm._v(
        " " +
          _vm._s(_vm.grandTotal ? _vm.totalNetAmount : _vm.netAmountPerUnit) +
          " "
      ),
    ]),
    _c("td", { staticClass: "py-1 w-10" }, [
      _vm._v(" " + _vm._s(_vm.tax) + " "),
    ]),
    _c("td", { staticClass: "py-1 w-10" }, [
      _vm._v(
        " " + _vm._s(_vm.grandTotal ? _vm.totalVatAmount : _vm.vatPerUnit) + " "
      ),
    ]),
    _c("td", { staticClass: "py-1 pr-3 w-10" }, [
      _vm._v(" " + _vm._s(_vm.totalGrossAmount) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }