<script>
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import { flatObject } from '@emobg/web-utils';
import { createNamespacedHelpers } from 'vuex';
import { MuiInputText, MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';
import { BUTTON_TYPES } from '@emobg/vue-base';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { scopes } from '@domains/FleetInvoicing/Invoices/store/InvoicesModule';
import { CancelButton, GenericModalComponent } from '@/components';
import AlertComponent from '@/components/AlertComponent/AlertComponent';
import WarningComponent from '@/components/AlertComponent/WarningComponent';

const { mapActions, mapState } = createNamespacedHelpers(
  DOMAINS_MODEL.fleetInvoicing.invoices,
);

export default {
  name: 'EditProformaModal',
  components: {
    AlertComponent,
    CancelButton,
    WarningComponent,
    GenericModalComponent,
    MuiValidationWrapper,
    MuiInputText,
  },
  directives: {
    Validate,
  },
  data() {
    return {
      isOpen: false,
      isAlertVisible: false,
      inputs: {},
    };
  },
  computed: {
    ...mapState({
      invoice: state => state[scopes.invoice].data.data,
      isLoaded: state => state[scopes.invoice].STATUS.LOADED,
      isLoading: state => state[scopes.invoice].STATUS.LOADING,
    }),
    normalizeInvoice() {
      return mapKeys(flatObject(this.invoice), (_value, key) => camelCase(key));
    },
  },
  watch: {
    isLoaded(isLoaded) {
      if (isLoaded) {
        this.$notify({
          message: 'Proforma edited successfully',
          textAction: 'OK',
        });
        this.isOpen = false;
      }
    },
  },
  created() {
    this.inputs = this.normalizeInvoice;
    this.BUTTON_TYPES = BUTTON_TYPES;
  },
  methods: {
    ...mapActions(['editProforma']),
    onSubmit() {
      this.isAlertVisible = true;
    },
    send() {
      this.payload = {
        invoiceUuid: this.invoice.uuid,
        editProforma: {
          purchaseOrderNumber: this.inputs.purchaseOrderNumber,
          customerOtherDetails: this.inputs.customerOtherDetails,
          footer: this.inputs.footer,
        },
      };
      this.editProforma(this.payload);
    },
    onAlertConfirm() {
      this.isAlertVisible = false;
      this.send();
    },
  },
};
</script>
<template>
  <ui-form @submitform="onSubmit">
    <ui-button
      :face="FACES.outline"
      :color="GRAYSCALE.inkLight"
      square
      class="ml-2"
      @clickbutton="isOpen = true"
    >
      <ui-icon
        :icon="ICONS.edit"
        :size="ICONS_SIZES.small"
      />
    </ui-button>
    <MuiValidationWrapper>
      <template slot-scope="{ areAllValid }">
        <GenericModalComponent
          v-if="isOpen"
          title="Edit proforma"
          data-test-id="edit-proforma-modal"
          :header="{ isClosable: true }"
          :size="SIZES.medium"
        >
          <template #body>
            <div class="row">
              <div class="col">
                <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                  {{ $t('FleetInvoicing.Common.Invoices.customer_name') }}
                </label>
                <MuiInputText
                  v-model="inputs.customerName"
                  disabled
                  name="customerName"
                  data-test-id="edit-proforma-modal-customer-name"
                  placeholder="Customer name"
                />
                <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                  {{ $t('FleetInvoicing.Common.Invoices.fiscal_number') }}
                </label>
                <MuiInputText
                  v-model="inputs.customerCif"
                  disabled
                  name="customerCif"
                  data-test-id="edit-proforma-modal-fiscal-number"
                  placeholder="Fiscal number"
                />
                <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                  {{ $t('FleetInvoicing.Common.Invoices.address') }}
                </label>
                <MuiInputText
                  v-model="inputs.customerAddressLine1"
                  disabled
                  name="customerAddressLine1"
                  data-test-id="edit-proforma-modal-line1"
                  placeholder="Address"
                />
                <MuiInputText
                  v-model="inputs.customerAddressLine2"
                  disabled
                  class="mt-2"
                  name="customerAddressLine2"
                  data-test-id="edit-proforma-modal-line2"
                  placeholder=""
                />
                <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                  {{ $t('FleetInvoicing.Common.Invoices.city') }}
                </label>
                <MuiInputText
                  v-model="inputs.customerAddressCity"
                  disabled
                  name="customerAddressCity"
                  data-test-id="edit-proforma-modal-city"
                  placeholder="City"
                />
                <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                  {{ $t('FleetInvoicing.Common.Invoices.postal_code') }}
                </label>
                <MuiInputText
                  v-model="inputs.customerAddressPostalCode"
                  disabled
                  name="customerAddressPostalCode"
                  data-test-id="edit-proforma-modal-postal-code"
                  placeholder="Postal code"
                />
                <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                  {{ $t('FleetInvoicing.Common.Invoices.country') }}
                </label>
                <MuiInputText
                  v-model="inputs.customerAddressCountry"
                  disabled
                  name="customerAddressCountry"
                  data-test-id="edit-proforma-modal-country"
                  placeholder="Country"
                />
              </div>
              <div class="col emobg-border-left-1 emobg-border-color-ground-light">
                <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                  {{ $t('FleetInvoicing.Common.Invoices.email') }}
                </label>
                <MuiInputText
                  v-model="inputs.customerEmail"
                  disabled
                  name="customerEmail"
                  data-test-id="edit-proforma-modal-customer-email"
                  placeholder="Email"
                />
                <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                  {{ $t('FleetInvoicing.Common.Invoices.purchase_order_number') }} ({{ $t('Common.Descriptions.optional') }})
                </label>
                <MuiInputText
                  v-model="inputs.purchaseOrderNumber"
                  name="purchaseOrderNumber"
                  data-test-id="edit-proforma-modal-purchase-order-number"
                  placeholder="purchase order number"
                />
                <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                  {{ $t('FleetInvoicing.Common.Invoices.other_details') }} ({{ $t('Common.Descriptions.optional') }})
                </label>
                <MuiInputText
                  v-model="inputs.customerOtherDetails"
                  name="otherDetails"
                  data-test-id="edit-proforma-modal-other-details"
                  placeholder="other details"
                />
                <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                  {{ $t('FleetInvoicing.Common.Invoices.invoice_footer') }} ({{ $t('Common.Descriptions.optional') }})
                </label>
                <MuiInputText
                  v-model="inputs.footer"
                  name="footer"
                  data-test-id="edit-proforma-modal-invoice-footer"
                  placeholder="footer"
                />
              </div>
            </div>
          </template>
          <template #footer>
            <div class="d-flex align-content-center">
              <CancelButton
                data-test-id="edit-proforma-modal-cancel"
                @click="isOpen = false"
              />
              <ui-button
                :disabled="!areAllValid"
                :type="BUTTON_TYPES.submit"
                data-test-id="edit-proforma-modal-send"
              >
                {{ $t('Common.Actions.save') }}
              </ui-button>
            </div>
          </template>
        </GenericModalComponent>
      </template>
    </MuiValidationWrapper>
    <AlertComponent
      v-if="isAlertVisible"
      @action="onAlertConfirm"
      @cancel="isAlertVisible = false"
    >
      <WarningComponent :title="$t('FleetInvoicing.Common.Invoices.status.warning.title')">
        <template slot="content">
          <p>{{ $t('FleetInvoicing.Common.Invoices.status.warning.content') }}</p>
        </template>
      </WarningComponent>
    </AlertComponent>
  </ui-form>
</template>
