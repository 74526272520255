<script>
import concat from 'lodash/concat';
import { sentenceCase } from '@emobg/web-utils';

export default {
  name: 'FiscalInfo',
  props: {
    title: {
      type: String,
      required: true,
    },
    lines: {
      type: [Array, String],
      required: true,
    },
  },
  methods: {
    concat,
    sentenceCase,
  },
};
</script>

<template>
  <section class="FiscalInfo emobg-font-weight-bold mb-6">
    <h2
      class="emobg-font-default emobg-font-weight-bold text-uppercase mb-2"
      data-test-id="FiscalInfo__title"
    >
      {{ sentenceCase(title) }}
    </h2>
    <p
      v-for="(line, index) in concat(lines)"
      :key="index"
      class="mb-2 emobg-color-ink-light"
      data-test-id="FiscalInfo__line"
    >
      {{ line }}
    </p>
  </section>
</template>
