<script>
import ModalActions from '@/components/ModalForm/ModalActions';

export default {
  name: 'AlertComponent',
  components: { ModalActions },
  props: {
    actionLabel: {
      type: String,
      default: 'Confirm',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onAction() {
      this.$emit('action');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <Transition name="modal">
    <aside class="backdrop position-fixed d-flex justify-content-center">
      <div class="Modal m-auto position-relative AlertComponent__layout">
        <div class="emobg-background-color-white full-min-height emobg-border-radius-large overflow-hidden">
          <main class="px-3 py-5">
            <slot name="default" />
          </main>
          <footer
            class="
              Modal__footer emobg-border-top-1 emobg-border-color-ground-light
              px-5 py-3
              emobg-background-color-ground-lightest
            "
          >
            <ModalActions
              data-test-id="modal-actions"
              :is-loading="isLoading"
              :action-label="actionLabel"
              @action="onAction"
              @cancel="onCancel"
            />
          </footer>
        </div>
      </div>
    </aside>
  </Transition>
</template>

<style scoped lang="scss">
.AlertComponent {
  &__layout {
    max-width: 440px;
  }
}
</style>
