var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-form",
    { on: { submitform: _vm.onSubmit } },
    [
      _c(
        "ui-button",
        {
          staticClass: "ml-2",
          attrs: {
            face: _vm.FACES.outline,
            color: _vm.GRAYSCALE.inkLight,
            square: "",
          },
          on: {
            clickbutton: function ($event) {
              _vm.isOpen = true
            },
          },
        },
        [
          _c("ui-icon", {
            attrs: { icon: _vm.ICONS.edit, size: _vm.ICONS_SIZES.small },
          }),
        ],
        1
      ),
      _c("MuiValidationWrapper", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ areAllValid }) {
              return [
                _vm.isOpen
                  ? _c("GenericModalComponent", {
                      attrs: {
                        title: "Edit proforma",
                        "data-test-id": "edit-proforma-modal",
                        header: { isClosable: true },
                        size: _vm.SIZES.medium,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FleetInvoicing.Common.Invoices.customer_name"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("MuiInputText", {
                                        attrs: {
                                          disabled: "",
                                          name: "customerName",
                                          "data-test-id":
                                            "edit-proforma-modal-customer-name",
                                          placeholder: "Customer name",
                                        },
                                        model: {
                                          value: _vm.inputs.customerName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "customerName",
                                              $$v
                                            )
                                          },
                                          expression: "inputs.customerName",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FleetInvoicing.Common.Invoices.fiscal_number"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("MuiInputText", {
                                        attrs: {
                                          disabled: "",
                                          name: "customerCif",
                                          "data-test-id":
                                            "edit-proforma-modal-fiscal-number",
                                          placeholder: "Fiscal number",
                                        },
                                        model: {
                                          value: _vm.inputs.customerCif,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "customerCif",
                                              $$v
                                            )
                                          },
                                          expression: "inputs.customerCif",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FleetInvoicing.Common.Invoices.address"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("MuiInputText", {
                                        attrs: {
                                          disabled: "",
                                          name: "customerAddressLine1",
                                          "data-test-id":
                                            "edit-proforma-modal-line1",
                                          placeholder: "Address",
                                        },
                                        model: {
                                          value:
                                            _vm.inputs.customerAddressLine1,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "customerAddressLine1",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputs.customerAddressLine1",
                                        },
                                      }),
                                      _c("MuiInputText", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          disabled: "",
                                          name: "customerAddressLine2",
                                          "data-test-id":
                                            "edit-proforma-modal-line2",
                                          placeholder: "",
                                        },
                                        model: {
                                          value:
                                            _vm.inputs.customerAddressLine2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "customerAddressLine2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputs.customerAddressLine2",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FleetInvoicing.Common.Invoices.city"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("MuiInputText", {
                                        attrs: {
                                          disabled: "",
                                          name: "customerAddressCity",
                                          "data-test-id":
                                            "edit-proforma-modal-city",
                                          placeholder: "City",
                                        },
                                        model: {
                                          value: _vm.inputs.customerAddressCity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "customerAddressCity",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputs.customerAddressCity",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FleetInvoicing.Common.Invoices.postal_code"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("MuiInputText", {
                                        attrs: {
                                          disabled: "",
                                          name: "customerAddressPostalCode",
                                          "data-test-id":
                                            "edit-proforma-modal-postal-code",
                                          placeholder: "Postal code",
                                        },
                                        model: {
                                          value:
                                            _vm.inputs
                                              .customerAddressPostalCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "customerAddressPostalCode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputs.customerAddressPostalCode",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FleetInvoicing.Common.Invoices.country"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("MuiInputText", {
                                        attrs: {
                                          disabled: "",
                                          name: "customerAddressCountry",
                                          "data-test-id":
                                            "edit-proforma-modal-country",
                                          placeholder: "Country",
                                        },
                                        model: {
                                          value:
                                            _vm.inputs.customerAddressCountry,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "customerAddressCountry",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputs.customerAddressCountry",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col emobg-border-left-1 emobg-border-color-ground-light",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FleetInvoicing.Common.Invoices.email"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("MuiInputText", {
                                        attrs: {
                                          disabled: "",
                                          name: "customerEmail",
                                          "data-test-id":
                                            "edit-proforma-modal-customer-email",
                                          placeholder: "Email",
                                        },
                                        model: {
                                          value: _vm.inputs.customerEmail,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "customerEmail",
                                              $$v
                                            )
                                          },
                                          expression: "inputs.customerEmail",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FleetInvoicing.Common.Invoices.purchase_order_number"
                                                )
                                              ) +
                                              " (" +
                                              _vm._s(
                                                _vm.$t(
                                                  "Common.Descriptions.optional"
                                                )
                                              ) +
                                              ") "
                                          ),
                                        ]
                                      ),
                                      _c("MuiInputText", {
                                        attrs: {
                                          name: "purchaseOrderNumber",
                                          "data-test-id":
                                            "edit-proforma-modal-purchase-order-number",
                                          placeholder: "purchase order number",
                                        },
                                        model: {
                                          value: _vm.inputs.purchaseOrderNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "purchaseOrderNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputs.purchaseOrderNumber",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FleetInvoicing.Common.Invoices.other_details"
                                                )
                                              ) +
                                              " (" +
                                              _vm._s(
                                                _vm.$t(
                                                  "Common.Descriptions.optional"
                                                )
                                              ) +
                                              ") "
                                          ),
                                        ]
                                      ),
                                      _c("MuiInputText", {
                                        attrs: {
                                          name: "otherDetails",
                                          "data-test-id":
                                            "edit-proforma-modal-other-details",
                                          placeholder: "other details",
                                        },
                                        model: {
                                          value:
                                            _vm.inputs.customerOtherDetails,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "customerOtherDetails",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputs.customerOtherDetails",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FleetInvoicing.Common.Invoices.invoice_footer"
                                                )
                                              ) +
                                              " (" +
                                              _vm._s(
                                                _vm.$t(
                                                  "Common.Descriptions.optional"
                                                )
                                              ) +
                                              ") "
                                          ),
                                        ]
                                      ),
                                      _c("MuiInputText", {
                                        attrs: {
                                          name: "footer",
                                          "data-test-id":
                                            "edit-proforma-modal-invoice-footer",
                                          placeholder: "footer",
                                        },
                                        model: {
                                          value: _vm.inputs.footer,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.inputs, "footer", $$v)
                                          },
                                          expression: "inputs.footer",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-content-center",
                                  },
                                  [
                                    _c("CancelButton", {
                                      attrs: {
                                        "data-test-id":
                                          "edit-proforma-modal-cancel",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isOpen = false
                                        },
                                      },
                                    }),
                                    _c(
                                      "ui-button",
                                      {
                                        attrs: {
                                          disabled: !areAllValid,
                                          type: _vm.BUTTON_TYPES.submit,
                                          "data-test-id":
                                            "edit-proforma-modal-send",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("Common.Actions.save")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.isAlertVisible
        ? _c(
            "AlertComponent",
            {
              on: {
                action: _vm.onAlertConfirm,
                cancel: function ($event) {
                  _vm.isAlertVisible = false
                },
              },
            },
            [
              _c(
                "WarningComponent",
                {
                  attrs: {
                    title: _vm.$t(
                      "FleetInvoicing.Common.Invoices.status.warning.title"
                    ),
                  },
                },
                [
                  _c("template", { slot: "content" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "FleetInvoicing.Common.Invoices.status.warning.content"
                          )
                        )
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }