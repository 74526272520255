<script>
export default {
  name: 'CompanyCorporateInvoicingView',
};
</script>

<template>
  <div
    class="CompanyCorporateInvoicingView"
    data-test-id="company_corporate_invoicing-view"
  >
    <RouterView />
  </div>
</template>
