var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "h2",
      {
        staticClass: "mb-3 emobg-color-danger",
        attrs: { "data-test-id": "title" },
      },
      [_vm._v(" " + _vm._s(_vm.title) + " ")]
    ),
    _c("div", { attrs: { "data-test-id": "content" } }, [_vm._t("content")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }