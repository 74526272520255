var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "InvoiceTable" }, [
    _c(
      "h3",
      {
        staticClass: "InvoiceTable__title emobg-font-xx-large mb-3",
        attrs: { "data-test-id": "invoice-table-title" },
      },
      [_vm._v(" " + _vm._s(_vm.title) + " ")]
    ),
    _c(
      "table",
      {
        staticClass:
          "w-100 text-left emobg-border-1 emobg-border-color-ground-light mb-4",
      },
      [
        _c("thead", { class: _vm.tableHeadClasses }, [
          _c("tr", [
            _c(
              "th",
              {
                staticClass: "pl-2 py-3",
                attrs: { colspan: _vm.detailsHeaderColspan },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.upperFirst(_vm.$t("Common.Descriptions.details"))
                    ) +
                    " "
                ),
              ]
            ),
            !_vm.grandTotal
              ? _c("th", { staticClass: "py-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.upperFirst(_vm.$t("Common.Descriptions.units"))
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _c("th", { staticClass: "py-3" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.upperFirst(
                      _vm.$t("Common.Descriptions.net_element", {
                        element: _vm.$t("Common.Descriptions.amount"),
                      })
                    )
                  ) +
                  " "
              ),
            ]),
            _c("th", { staticClass: "py-3" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.upperFirst(
                      _vm.$t("Common.Business.tax_element", {
                        element: _vm.$t("Common.Business.rate"),
                      })
                    )
                  ) +
                  " "
              ),
            ]),
            _c("th", { staticClass: "py-3" }, [
              _vm._v(
                " " + _vm._s(_vm.upperCase(_vm.$t("Common.Business.vat"))) + " "
              ),
            ]),
            _c("th", { staticClass: "py-3" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.upperFirst(_vm.$t("Common.Descriptions.total"))) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.lines, function (line, index) {
              return _c(
                "InvoiceLine",
                _vm._b(
                  {
                    key: `${_vm.title}-line${index}`,
                    attrs: {
                      "grand-total": _vm.grandTotal,
                      first: index === 0,
                      last: index === _vm.lines.length - 1,
                    },
                  },
                  "InvoiceLine",
                  { ...line },
                  false
                )
              )
            }),
            _vm._l(_vm.groups, function (group) {
              return [
                _c(
                  "th",
                  {
                    key: group.id,
                    staticClass: "emobg-font-weight-bold py-4 pl-3",
                    attrs: { colspan: "6" },
                  },
                  [_vm._v(" " + _vm._s(group.name) + " ")]
                ),
                _vm._l(group.lines, function (line, index) {
                  return _c(
                    "InvoiceLine",
                    _vm._b(
                      {
                        key: `${group.id}-line${index}`,
                        attrs: {
                          "data-test-id": `group[${group.id}] - ${line.name}`,
                          last:
                            _vm.isLastIn(group, _vm.groups) &&
                            _vm.isLastIn(line, group.lines),
                        },
                      },
                      "InvoiceLine",
                      { ...line },
                      false
                    )
                  )
                }),
              ]
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }