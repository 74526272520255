var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-form",
    { on: { submitform: _vm.onSubmit } },
    [
      _c("MuiValidationWrapper", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ areAllValid }) {
              return [
                _c(
                  "GenericModalComponent",
                  _vm._b(
                    {
                      attrs: { size: _vm.SIZES.medium },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                      },
                                      [_vm._v(" To ")]
                                    ),
                                    _c("MuiInputText", {
                                      staticClass: "w-100 mb-3",
                                      attrs: {
                                        type: "email",
                                        name: "to",
                                        "data-test-id": "send-review-modal-to",
                                        placeholder: "To",
                                      },
                                      model: {
                                        value: _vm.inputs.email,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.inputs, "email", $$v)
                                        },
                                        expression: "inputs.email",
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                      },
                                      [_vm._v(" Subject ")]
                                    ),
                                    _c("MuiInputText", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate.blur",
                                          value: {
                                            isRequired: true,
                                          },
                                          expression:
                                            "{\n                isRequired: true,\n              }",
                                          modifiers: { blur: true },
                                        },
                                      ],
                                      staticClass: "w-100 mb-3",
                                      attrs: {
                                        name: "subject",
                                        "data-test-id":
                                          "send-review-modal-subject",
                                        placeholder: "Subject",
                                      },
                                      model: {
                                        value: _vm.inputs.subject,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.inputs, "subject", $$v)
                                        },
                                        expression: "inputs.subject",
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "d-block my-2 emobg-body-small emobg-font-weight-bold",
                                      },
                                      [_vm._v(" Message ")]
                                    ),
                                    _c("MuiTextarea", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate.blur",
                                          value: {
                                            isRequired: true,
                                          },
                                          expression:
                                            "{\n                isRequired: true,\n              }",
                                          modifiers: { blur: true },
                                        },
                                      ],
                                      staticClass: "w-100 mb-3",
                                      attrs: {
                                        name: "message",
                                        "data-test-id":
                                          "send-review-modal-message",
                                        placeholder: "Message",
                                      },
                                      model: {
                                        value: _vm.inputs.message,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.inputs, "message", $$v)
                                        },
                                        expression: "inputs.message",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-content-center",
                                  },
                                  [
                                    _c("CancelButton", {
                                      attrs: {
                                        "data-test-id":
                                          "send-review-modal-cancel",
                                      },
                                      on: { click: _vm.closeModal },
                                    }),
                                    _c(
                                      "ui-button",
                                      {
                                        attrs: {
                                          disabled: !areAllValid,
                                          type: _vm.BUTTON_TYPES.submit,
                                          "data-test-id":
                                            "send-review-modal-send",
                                        },
                                      },
                                      [_vm._v(" Send ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    "GenericModalComponent",
                    _vm.$attrs,
                    false
                  )
                ),
              ]
            },
          },
        ]),
      }),
      _vm.isAlertVisible
        ? _c(
            "AlertComponent",
            {
              staticClass: "z-index-1100",
              on: {
                action: _vm.onAlertConfirm,
                cancel: function ($event) {
                  _vm.isAlertVisible = false
                },
              },
            },
            [
              _c(
                "WarningComponent",
                {
                  attrs: {
                    title: _vm.$t(
                      "FleetInvoicing.Common.Invoices.status.warning.title"
                    ),
                  },
                },
                [
                  _c("template", { slot: "content" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "FleetInvoicing.Common.Invoices.status.warning.content"
                          )
                        )
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }