var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "d-flex justify-content-end" },
    [
      _c(
        "ui-button",
        {
          staticClass: "mr-2",
          attrs: {
            face: _vm.FACES.text,
            color: _vm.GRAYSCALE.inkLight,
            name: "cancel",
          },
          on: { clickbutton: _vm.onCancel },
        },
        [_vm._v(" Cancel ")]
      ),
      _c(
        "ui-button",
        {
          staticClass: "mr-2",
          attrs: { loading: _vm.loading, name: "save" },
          on: { clickbutton: _vm.onAction },
        },
        [_vm._v(" " + _vm._s(_vm.actionLabel) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }