var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InvoicesView container-fluid" },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Invoices list ")]),
      _c(
        "ui-card",
        [
          _c("MuiAlgoliaList", {
            attrs: {
              facets: _vm.invoicesListFacets,
              "table-config": _vm.invoicesConfig,
              filters: _vm.filterByIssuer(_vm.companyUuid),
              index: _vm.ALGOLIA_INDEXES.fpInvoices,
              "empty-message": "No invoices found",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }