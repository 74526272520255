<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import InvoiceActions from '../components/InvoiceActions';
import { scopes } from '../store/InvoicesModule';
import InvoiceDetail from './InvoiceDetail';

export default {
  name: 'InvoiceDetailView',
  components: {
    InvoiceActions,
    InvoiceDetail,
  },
  data() {
    return { invoiceUuid: null };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleetInvoicing.invoices, {
      invoiceCompiled: state => state[scopes.invoiceCompiled].data.data,
      invoice: state => state[scopes.invoice].data.data,
      isLoading: state => state[scopes.invoiceCompiled].STATUS.LOADING,
      isError: state => state[scopes.invoiceCompiled].STATUS.ERROR,
    }),
  },
  watch: {
    invoice() {
      this.refreshCompiledInvoice();
    },
  },
  created() {
    this.invoiceUuid = get(this, '$route.params.invoice_uuid');
    this.getCompiledInvoice(this.invoiceUuid);
    this.getInvoice(this.invoiceUuid);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.invoices, [
      'getCompiledInvoice',
      'getInvoice',
    ]),
    refreshCompiledInvoice() {
      this.getCompiledInvoice(this.invoiceUuid);
    },
  },
};
</script>

<template>
  <main class="InvoiceDetailView container-fluid py-4">
    <ui-loader
      v-if="isLoading && !invoiceCompiled"
      data-test-id="loader-container"
    />
    <section v-if="invoiceCompiled">
      <h1 class="emobg-font-large mb-3">
        {{ invoiceCompiled.reference }}
      </h1>
      <ui-card data-test-id="invoice-container">
        <InvoiceActions
          :invoice-uuid="invoiceCompiled.uuid"
          class="InvoiceDetailView__actions d-flex justify-content-end mb-4"
        />
        <InvoiceDetail :invoice-compiled="invoiceCompiled" />
      </ui-card>
    </section>
    <section v-if="isError">
      <ui-card data-test-id="error-container">
        There has been a problem loading the invoice.
      </ui-card>
    </section>
  </main>
</template>

<style lang="scss">
.InvoiceDetailView {
  &__issuer-info,
  &__customer-info {
    width: 280px;
  }
}
</style>
