<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';

import { mapState } from 'vuex';
import { TIME_ZONE } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

import { getNoResultsLabel } from '@/utils';
import { filterByIssuer } from '../algoliaFilters/filterByIssuer';
import { invoices as invoicesConfig, invoicesListFacets } from './config/InvoicesAlgoliaTables';

export default {
  name: 'InvoicesView',
  components: {
    MuiAlgoliaList,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
  },
  created() {
    this.companyUuid = this.company.uuid;
    this.invoicesConfig = invoicesConfig.bind(this)(this.companyUuid);
    const timezone = this.operatorTimezone || TIME_ZONE.default;
    this.invoicesListFacets = invoicesListFacets.bind(this)(timezone);
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.filterByIssuer = filterByIssuer;
  },
  methods: { getNoResultsLabel },
};
</script>

<template>
  <div class="InvoicesView container-fluid">
    <h1 class="mb-2">
      Invoices list
    </h1>
    <ui-card>
      <MuiAlgoliaList
        :facets="invoicesListFacets"
        :table-config="invoicesConfig"
        :filters="filterByIssuer(companyUuid)"
        :index="ALGOLIA_INDEXES.fpInvoices"
        empty-message="No invoices found"
      />
    </ui-card>
  </div>
</template>
