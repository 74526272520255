var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "InvoiceDetailView container-fluid py-4" },
    [
      _vm.isLoading && !_vm.invoiceCompiled
        ? _c("ui-loader", { attrs: { "data-test-id": "loader-container" } })
        : _vm._e(),
      _vm.invoiceCompiled
        ? _c(
            "section",
            [
              _c("h1", { staticClass: "emobg-font-large mb-3" }, [
                _vm._v(" " + _vm._s(_vm.invoiceCompiled.reference) + " "),
              ]),
              _c(
                "ui-card",
                { attrs: { "data-test-id": "invoice-container" } },
                [
                  _c("InvoiceActions", {
                    staticClass:
                      "InvoiceDetailView__actions d-flex justify-content-end mb-4",
                    attrs: { "invoice-uuid": _vm.invoiceCompiled.uuid },
                  }),
                  _c("InvoiceDetail", {
                    attrs: { "invoice-compiled": _vm.invoiceCompiled },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isError
        ? _c(
            "section",
            [
              _c("ui-card", { attrs: { "data-test-id": "error-container" } }, [
                _vm._v(" There has been a problem loading the invoice. "),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }