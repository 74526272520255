import { COLORS, GRAYSCALE } from '@emobg/vue-base';

export const INVOICE_STATUS = {
  inReview: 'review',
  draft: 'draft',
  issued: 'issued',
  issuing: 'issuing',
  paid: 'paid',
};

export const INVOICE_STATUS_COLORS = {
  [INVOICE_STATUS.inReview]: COLORS.warning,
  [INVOICE_STATUS.draft]: COLORS.moderate,
  [INVOICE_STATUS.issued]: GRAYSCALE.ground,
  [INVOICE_STATUS.issuing]: GRAYSCALE.ground,
  [INVOICE_STATUS.paid]: GRAYSCALE.ground,
};

export const INVOICE_ACTIONS = {
  sendForReview: 'send_for_review',
  issue: 'issue',
  modify: 'modify',
};
