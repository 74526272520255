<script>
export default {
  name: 'ModalActions',
  props: {
    actionLabel: {
      type: String,
      default: 'Save',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { loading: this.isLoading };
  },
  watch: {
    isLoading(isLoading) {
      this.loading = isLoading;
    },
  },
  methods: {
    onAction() { this.$emit('action'); },
    onCancel() { this.$emit('cancel'); },
  },
};
</script>
<template>
  <section class="d-flex justify-content-end">
    <ui-button
      :face="FACES.text"
      :color="GRAYSCALE.inkLight"
      class="mr-2"
      name="cancel"
      @clickbutton="onCancel"
    >
      Cancel
    </ui-button>
    <ui-button
      :loading="loading"
      class="mr-2"
      name="save"
      @clickbutton="onAction"
    >
      {{ actionLabel }}
    </ui-button>
  </section>
</template>
