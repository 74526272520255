import { RECORD_TYPES } from '@emobg/motion-ui';
import {
  DATE_FORMAT,
  formatCurrency,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { INVOICE_STATUS_COLORS } from '../constants';
import Currency from '../../utils/currency';

export function invoices(companyUuid) {
  return [
    {
      title: 'Invoice ID',
      attributeName: 'uuid',
      displayPriority: 1,
      columnRatio: 1,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: ({ uuid }) => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: crm.companies.detail.corporateInvoicing.detail,
          params: {
            companyUuid,
            invoice_uuid: uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmCompanies],
        text: uuid.substr(0, 7),
      }),
    },
    {
      attributeName: 'start_date',
      title: 'Date',
      displayPriority: 1,
      columnRatio: 1,
      transformValue: value => formatUtc(value, DATE_FORMAT.dob),
    },
    {
      attributeName: 'status',
      title: 'Status',
      displayPriority: 1,
      columnRatio: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: INVOICE_STATUS_COLORS[status],
      }),
    },
    {
      attributeName: 'amount',
      title: 'Amount',
      displayPriority: 1,
      columnRatio: 1,
      type: RECORD_TYPES.template,
      template: ({ amount, currency }) => formatCurrency(Currency.parse(amount), currency),
    },
    {
      attributeName: 'reference',
      title: 'Invoice plan',
      displayPriority: 2,
      columnRatio: 2,
      type: RECORD_TYPES.template,
      template: ({ billing_reference: billingReference }) => billingReference,
    },
    {
      attributeName: 'document',
      title: 'Document',
      displayPriority: 2,
    },
  ];
}

export function invoicesListFacets(timezone) {
  return [
    {
      type: 'rangeDateTimePicker',
      props: {
        title: 'Date',
        attributeName: 'start_date_ts',
        timezone,
        labels: {
          from: 'Start date',
          to: 'End date',
          removeButton: 'Clear dates',
        },

      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Status',
        attributeName: 'status',
      },
    },
    {
      type: 'rangeslider',
      props: {
        title: 'Amount',
        attributeName: 'amount',
        toggled: false,
        showTooltip: true,
        format: value => value,
        pipValues: [],
      },
    },
  ];
}
