<script>
import capitalize from 'lodash/capitalize';
import { createNamespacedHelpers } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import SendInvoiceToReviewModal from './SendInvoiceToReviewModal';
import { scopes } from '../store/InvoicesModule';
import { INVOICE_ACTIONS, INVOICE_STATUS } from '../constants';
import ModifyInvoiceAlert from './ModifyInvoiceAlert';
import IssueInvoiceAlert from './IssueInvoiceAlert';
import EditProformaModal from './EditProformaModal';
import DownloadInvoice from './DownloadInvoice';

const { mapActions, mapState } = createNamespacedHelpers(
  DOMAINS_MODEL.fleetInvoicing.invoices,
);

export default {
  name: 'InvoiceActions',
  components: {
    IssueInvoiceAlert,
    EditProformaModal,
    ModifyInvoiceAlert,
    SendInvoiceToReviewModal,
    DownloadInvoice,
  },
  props: {
    invoiceUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentOperation: null,
      invoiceStatus: null,
    };
  },
  computed: {
    ...mapState({
      invoice: state => state[scopes.invoice].data.data,
      isError: state => state[scopes.invoice].STATUS.ERROR,
      error: state => state[scopes.invoice].error,
      isLoaded: state => state[scopes.invoice].STATUS.LOADED,
    }),
    invoiceStatusCapitalized() {
      return capitalize(this.invoice.status);
    },
  },
  watch: {
    invoice(invoice) {
      this.invoiceStatus = invoice.status;
    },
    isError(isError) {
      if (isError) {
        const errorMessage = this.error.message;
        this.displayError(errorMessage);
      }
    },
  },
  created() {
    this.actionList = [
      { label: 'Issue invoice', action: this.alertIssueInvoice },
      { label: 'Modify invoice', action: this.alertModifyInvoice },
    ];
    this.INVOICE_ACTIONS = INVOICE_ACTIONS;
    this.INVOICE_STATUS = INVOICE_STATUS;
  },
  methods: {
    ...mapActions(['issueInvoice', 'rejectInvoice', 'getInvoice']),
    onSendForReviewClick() {
      this.currentOperation = INVOICE_ACTIONS.sendForReview;
    },
    alertIssueInvoice() {
      this.currentOperation = INVOICE_ACTIONS.issue;
    },
    alertModifyInvoice() {
      this.currentOperation = INVOICE_ACTIONS.modify;
    },
    onModify() {
      this.rejectInvoice(this.invoice.uuid);
    },
    onIssue() {
      this.issueInvoice(this.invoice.uuid);
    },
    closeCurrentAction() {
      this.currentOperation = null;
    },
    displayError(message) {
      this.$throwError({ message });
    },
  },
};
</script>
<template>
  <section>
    <div
      v-if="!invoice"
      class="p-3"
    />
    <div
      v-else
      class="d-flex justify-content-end"
    >
      <EditProformaModal v-if="invoice.status === INVOICE_STATUS.draft" />
      <DownloadInvoice
        v-if="invoice.status === INVOICE_STATUS.issued"
        :invoice="invoice"
        @error="displayError"
      />
      <ui-button
        v-if="invoice.status === INVOICE_STATUS.draft"
        :face="FACES.outline"
        :size="SIZES.small"
        class="ml-2"
        data-test-id="send-for-review"
        @clickbutton="onSendForReviewClick"
      >
        {{ $t('FleetInvoicing.Common.Invoices.review.action') }}
      </ui-button>
      <ui-dropdown v-else-if="invoice.status === INVOICE_STATUS.inReview">
        <span slot="trigger">
          {{ invoiceStatusCapitalized }}
        </span>
        <ui-dropdown-actions
          slot="content"
          :actions.prop="actionList"
          data-test-id="dropdown-actions"
        />
      </ui-dropdown>
      <SendInvoiceToReviewModal
        v-if="currentOperation === INVOICE_ACTIONS.sendForReview"
        :invoice="invoice"
        title="Send to client for review"
        @closeModal="closeCurrentAction"
      />
      <ModifyInvoiceAlert
        v-else-if="currentOperation === INVOICE_ACTIONS.modify"
        @modify="onModify"
        @cancel="closeCurrentAction"
      />
      <IssueInvoiceAlert
        v-else-if="currentOperation === INVOICE_ACTIONS.issue"
        @issue="onIssue"
        @cancel="closeCurrentAction"
      />
    </div>
  </section>
</template>
