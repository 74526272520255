<script>
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import upperFirst from 'lodash/upperFirst';
import upperCase from 'lodash/upperCase';

import { camelCaseKeys } from '@emobg/web-utils';
import FiscalInfo from '../components/invoice/FiscalInfo';
import InvoiceTable from '../components/invoice/InvoiceTable';
import { INVOICE_STATUS } from '../constants';

export default {
  name: 'InvoiceDetail',
  components: {
    FiscalInfo,
    InvoiceTable,
  },
  props: {
    invoiceCompiled: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customerInfoLines() {
      return this.getOrderedFiscalDetails(this.invoiceCompiled.customer);
    },
    issuerInfoLines() {
      return this.getOrderedFiscalDetails(this.invoiceCompiled.issuerCompany);
    },
    rawGroups() {
      return this.getGroups(this.invoiceCompiled);
    },
    computedGroups() {
      return this.remixGroupsData(this.rawGroups);
    },
    invoiceTotalLine() {
      return camelCaseKeys(this.invoiceCompiled.invoiceTotal);
    },
    shouldShowInvoiceDate() {
      return includes([INVOICE_STATUS.issued, INVOICE_STATUS.paid], this.invoiceCompiled.status);
    },
    shouldShowDraftMessage() {
      return this.invoiceCompiled.status === INVOICE_STATUS.draft;
    },
  },
  methods: {
    get,
    upperCase,
    upperFirst,
    getOrderedFiscalDetails(fiscalEntity) {
      const {
        name, cif, email, otherDetails, address,
      } = fiscalEntity;
      const {
        address1, address2, city, postalCode, country,
      } = address;
      return [
        name,
        cif,
        address1,
        address2,
        [city, postalCode, country].join(', '),
        email,
        otherDetails,
      ];
    },
    getGroups: data => data.groups
      .reduce((acc, group) => {
        const isNestedGroup = group.parentId === 0 || group.parentId;
        const selectedGroupType = isNestedGroup ? 'nestedGroups' : 'mainGroups';
        const computedGroup = {
          ...group,
          ...(isNestedGroup ? {} : { groups: [] }),
          // Add their respective invoice lines
          ...{ lines: camelCaseKeys(filter(data.invoiceLines, ['group', group.id])) },
        };
        acc[selectedGroupType].push(computedGroup);
        return acc;
      }, {
        mainGroups: [],
        nestedGroups: [],
      }),
    remixGroupsData: ({ mainGroups, nestedGroups }) => nestedGroups
      .reduce((acc, group) => {
        const parent = find(mainGroups, ['id', group.parentId]);
        parent.groups = [...parent.groups, group];
        return [...acc];
      }, mainGroups),
  },
};
</script>

<template>
  <section data-test-id="compiledInvoice">
    <ui-alert
      v-if="shouldShowDraftMessage"
      data-test-id="draft-warning"
      :color="COLORS.warning"
      class="w-100"
    >
      Once the proforma is correct, please send it for review to client before issuing the final invoice.
    </ui-alert>
    <header class="mt-4 d-flex justify-content-between">
      <section class="InvoiceDetailView__issuer-info">
        <img
          :src="get(invoiceCompiled, 'issuerCompany.logo')"
          :alt="get(invoiceCompiled, 'issuerCompany.name')"
          class="InvoiceDetailView__issuer-info--logo"
        >
        <FiscalInfo
          :title="(upperFirst($t('Common.Business.issuer_element', {element: $t('Common.Descriptions.company_element', {element: $t('Common.Descriptions.details')})})))"
          :lines="issuerInfoLines"
          data-test-id="issuer-details"
        />
      </section>

      <section class="InvoiceDetailView__customer-info">
        <FiscalInfo
          v-if="shouldShowInvoiceDate"
          :lines="[invoiceCompiled.date]"
          title="Date"
          data-test-id="issue-date"
        />
        <FiscalInfo
          :title="(upperFirst($t('Common.Descriptions.invoice_element', {element: $t('Common.Descriptions.id')})))"
          :lines="[invoiceCompiled.reference]"
          data-test-id="invoice-id"
        />
        <FiscalInfo
          :title="(upperCase($t('Common.Actions.purchase_element', {element: $t('Common.Business.order')})))"
          :lines="[invoiceCompiled.purchaseOrderNumber]"
          data-test-id="purchase-order"
        />
        <FiscalInfo
          :title="(upperCase($t('Common.Descriptions.details_element', {element: $t('Common.Business.customer')})))"
          :lines="customerInfoLines"
          data-test-id="customer-details"
        />
      </section>
    </header>
    <InvoiceTable
      v-for="group in computedGroups"
      :key="group.id"
      :title="group.name"
      :groups="group.groups"
      :lines="group.lines"
    />
    <InvoiceTable
      grand-total
      class="mt-6"
      :lines="[invoiceTotalLine]"
    />
    <p class="w-50 mt-6">
      {{ invoiceCompiled.footer }}
    </p>
  </section>
</template>

<style lang="scss">
.InvoiceDetailView {
  &__issuer-info--logo {
    width: 150px;
  }

  &__issuer-info,
  &__customer-info {
    width: 280px;
  }
}
</style>
