<script>
import get from 'lodash/get';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { createNamespacedHelpers } from 'vuex';
import { BUTTON_TYPES } from '@emobg/vue-base';
import {
  MuiInputText,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';

import AlertComponent from '@/components/AlertComponent/AlertComponent';
import { CancelButton, GenericModalComponent } from '@/components';
import WarningComponent from '@/components/AlertComponent/WarningComponent';
import { scopes } from '../store/InvoicesModule';

const { mapActions, mapState } = createNamespacedHelpers(
  DOMAINS_MODEL.fleetInvoicing.invoices,
);

export default {
  name: 'SendInvoiceToReviewModal',
  components: {
    AlertComponent,
    CancelButton,
    WarningComponent,
    GenericModalComponent,
    MuiValidationWrapper,
    MuiInputText,
    MuiTextarea,
  },
  directives: {
    Validate,
  },
  data() {
    return {
      inputs: {
        email: undefined,
        subject: 'Kindly review attached Ubeeqo draft invoice',
        message: 'Dear Customer, please review the attached draft before we issue the invoice.',
      },
      isAlertVisible: false,
    };
  },
  computed: {
    ...mapState({
      compiledInvoice: state => state[scopes.invoiceCompiled].data.data,
      isLoaded: state => state[scopes.invoice].STATUS.LOADED,
      isError: state => state[scopes.invoice].STATUS.ERROR,
      error: state => state[scopes.invoice].error,
      isLoading: state => state[scopes.invoice].STATUS.LOADING,
    }),
  },
  watch: {
    isLoaded(value) {
      if (value) {
        this.close();
        this.displaySuccessMessage();
      }
    },
  },
  created() {
    this.inputs.email = get(this.compiledInvoice, 'customer.email');
    this.BUTTON_TYPES = BUTTON_TYPES;
  },
  methods: {
    ...mapActions(['sendInvoiceForReview']),
    send() {
      this.sendInvoiceForReview({
        invoiceUuid: this.compiledInvoice.uuid,
        data: this.inputs,
      });
      this.closeModal();
    },
    onSubmit() {
      this.isAlertVisible = true;
    },
    onAlertConfirm() {
      this.isAlertVisible = false;
      this.send();
    },
    closeModal() {
      this.$emit('closeModal');
    },
    displaySuccessMessage() {
      this.$notify({
        message: 'Invoice sent successfully for review',
        textAction: 'OK',
      });
    },
  },
};
</script>
<template>
  <ui-form @submitform="onSubmit">
    <MuiValidationWrapper>
      <template slot-scope="{ areAllValid }">
        <GenericModalComponent
          v-bind="$attrs"
          :size="SIZES.medium"
        >
          <template #body>
            <div>
              <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                To
              </label>
              <MuiInputText
                v-model="inputs.email"
                type="email"
                name="to"
                class="w-100 mb-3"
                data-test-id="send-review-modal-to"
                placeholder="To"
              />
              <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                Subject
              </label>
              <MuiInputText
                v-model="inputs.subject"
                v-validate.blur="{
                  isRequired: true,
                }"
                name="subject"
                class="w-100 mb-3"
                data-test-id="send-review-modal-subject"
                placeholder="Subject"
              />
              <label class="d-block my-2 emobg-body-small emobg-font-weight-bold">
                Message
              </label>
              <MuiTextarea
                v-model="inputs.message"
                v-validate.blur="{
                  isRequired: true,
                }"
                name="message"
                class="w-100 mb-3"
                data-test-id="send-review-modal-message"
                placeholder="Message"
              />
            </div>
          </template>
          <template #footer>
            <div class="d-flex align-content-center">
              <CancelButton
                data-test-id="send-review-modal-cancel"
                @click="closeModal"
              />
              <ui-button
                :disabled="!areAllValid"
                :type="BUTTON_TYPES.submit"
                data-test-id="send-review-modal-send"
              >
                Send
              </ui-button>
            </div>
          </template>
        </GenericModalComponent>
      </template>
    </MuiValidationWrapper>
    <AlertComponent
      v-if="isAlertVisible"
      class="z-index-1100"
      @action="onAlertConfirm"
      @cancel="isAlertVisible = false"
    >
      <WarningComponent :title="$t('FleetInvoicing.Common.Invoices.status.warning.title')">
        <template slot="content">
          <p>{{ $t('FleetInvoicing.Common.Invoices.status.warning.content') }}</p>
        </template>
      </WarningComponent>
    </AlertComponent>
  </ui-form>
</template>
