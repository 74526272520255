<script>
export default {
  name: 'WarningComponent',
  props: {
    title: { type: String, required: true },
  },
};
</script>
<template>
  <section>
    <h2
      class="mb-3 emobg-color-danger"
      data-test-id="title"
    >
      {{ title }}
    </h2>
    <div data-test-id="content">
      <slot name="content" />
    </div>
  </section>
</template>

