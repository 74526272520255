<script>
import { backOffice } from '@emobg/web-api-client';
import { exportFile } from '@emobg/web-utils';

export default {
  name: 'DownloadInvoice',
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { isDownloading: false };
  },
  methods: {
    getInvoiceFileName() {
      return `${this.invoice.reference}.pdf`;
    },
    download() {
      const { fleetInvoicing } = backOffice;
      this.isDownloading = true;

      fleetInvoicing.getDownloadInvoice(this.invoice.uuid)
        .then(response => {
          exportFile(response, this.getInvoiceFileName(), { type: 'application/pdf' });
        }).catch(error => {
          const errorMessage = error.message || 'Something went wrong while downloading this file';
          this.$emit('error', errorMessage);
        }).finally(() => {
          this.isDownloading = false;
        });
    },
  },
};
</script>
<template>
  <ui-button
    :face="FACES.outline"
    :color="GRAYSCALE.inkLight"
    :disabled="isDownloading"
    :loading="isDownloading"
    class="ml-2"
    square
    @clickbutton="download"
  >
    <ui-icon
      :icon="ICONS.download"
      :size="ICONS_SIZES.small"
      square
    />
  </ui-button>
</template>
